.similarProduct::-webkit-scrollbar {
    display: none;
}

.main-content {
    display: flex;
    justify-content: space-around;
    width: 90%;
    flex-wrap: wrap;
}

.product-image {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detail-img-box {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.detail-img-box .detail-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.product-details {
    width: 800px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.chip {
    display: flex;
    gap: 0.5rem
}

@media screen and (max-width: 960px) {
    .product-details {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width:1023px) {
    .product-details {
        width: 100%;
    }
}