.main_cart {
    width: 300px;
    margin: 30px 10px 40px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 15px; */
    height: 400px;
    padding-bottom: 15px;
    /* border: 2px solid red; */
}

.card_action {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid black; */
    flex-direction: column;
}

.img_box {
    height: 200px;
    width: 250px;
    /* padding: 5px; */
    display: flex;
    justify-content: center;
    align-items: center;

}

.img_box .img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@media screen and (max-width: 960px) {
    .main_cart {
        width: 250px;
        height: 390px;

    }

    .img_box  {
        padding: 15px 0;
        height: 200px;
        width: 250px;
        margin-top: 15px;

    }

}