.mainCard {
  height: 300px;
  width: 400px;
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  align-items: center;
  position: relative;
  cursor: pointer;
}

.mainCard:hover {
  transform: scale(1.05);
  transition: ease-in 0.1s;
}

.mainImg {
  height: 80%; /* Adjust height to fit title below */
  width: 100%;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.2);
  filter: saturate(0.7) contrast(1) brightness(1);
  object-fit: cover;
  border-radius: 10px;
}

.imgTitle {
  font-weight: 500;
  color: black; /* Change color to black */
  text-align: center;
  font-size: 20px;
  margin-top: 10px; /* Add spacing between image and title */
}

@media screen and (max-width: 960px) {
  .mainCard {
    height: 200px;
    width: 300px;
  }

  .mainImg {
    height: 70%; /* Adjust for smaller screens */
  }

  .imgTitle {
    font-size: 16px; /* Adjust title size */
  }
}
