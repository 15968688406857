.showMobile {
    display: none;
}

@media screen and (max-width: 900px) {
    .showMobile {
        display: block;
        /* border: 2px solid Red; */
        
    }

    .links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px;
        width: 70px;
    }

}

@media (min-width: 768px) and (max-width:1023px) {
    
}