.checkout_form {
    width: 950px;
    margin-bottom: 20px;
}

@media screen and (max-width: 960px) {
    .checkout_form {
        width: 100%;
    }
    .checkout_form button{
        /* background-color: red; */
        /* width: 150px; */
    }
}

@media (min-width: 768px) and (max-width:1023px) {
    .checkout_form {
        width: 100%;
    }
}